<template>
	<div
		class="flex-column full-height bg-gray position-relative"
	>
		<div class="full-height  flex-column justify-center">
			<div class="pa-20 justify-space-between-in-box ">
				<div
					@click="onAgreeInfo"
					class=" shadow bg-white pa-10"
				>
					<button>
						<v-icon v-if="is_agree_info" class="color-blue">mdi-checkbox-marked</v-icon>
						<v-icon v-else>mdi-checkbox-blank-outline</v-icon>
						서비스 이용동의
					</button>
					<button
						class="btn-inline btn-primary-outline"
					>보기</button>
				</div>

				<div
					class=" mt-20 shadow bg-white pa-10"
					@click="onAgreePayment"
				>
					<button
					>
						<v-icon v-if="is_agree_payment" class="color-blue">mdi-checkbox-marked</v-icon>
						<v-icon v-else>mdi-checkbox-blank-outline</v-icon>
						개인정보 약관 동의
					</button>
					<button
						class="btn-inline btn-primary-outline"
					>보기</button>
				</div>
			</div>
		</div>

		<div
			v-if="is_payment"
			class="mt-auto "
		>
			<button
				class="btn btn-primary"
				@click="toPayment"
				:disabled="is_disabled"
			>본인 인증 결제</button>
		</div>

		<PopupConfirm
			v-if="is_on_agree_info"
			@click="is_agree_info = true; offAgreeInfo()"
			@cancel="is_agree_info = false; offAgreeInfo()"
		>
			<template
				v-slot:title
			>서비스 이용동의</template>
			<template
				v-slot:main-txt
			>서비스 이용 동의사항
				<br/>
				<br/>
				1. 상담시간 안내
				<br/>
				- 평일 09:00 ~ 18:00
				<br/>
				<br/>
				2. 결제정보 발송안내
				<br/>
				- 보이스피싱, 해킹, 결제도용에 대한 피해를 방지하기 위하여 고객님의 결제정보를 고지하고 있습니다.
				<br/>
				- 고지내용 : 수신번호, 주문자명, 주문자 성별, 주문자 나이, 주문금액
				<br/>
				<br/>
				3. 주문취소 안내
				<br/>
				- 주문 취소는 고객센터로만 접수 가능합니다.
				<br/>
				- 부분취소가 불가합니다.
				<br/>
				- 주문완료 이후 7일 이내에 취소 가능합니다.
				<br/>
				- 취소신청시 해당결제사에 따라서 1일~3일 정도 소요될 수 있습니다.
				<br/>
				<br/>
			</template>
		</PopupConfirm>

		<PopupConfirm
			v-if="is_on_agree_payment"
			@click="is_agree_payment = true; offAgreePayment()"
			@cancel="is_agree_payment = false; offAgreePayment()"
		>
			<template
				v-slot:title
			>개인정보 동의</template>
			<template
				v-slot:main-txt
			>
				개인정보 수집 및 이용에 관한 안내
				<br/>
				<br/>
				1. 수집하는 개인정보의 항목
				<br/>
				이름, 휴대폰번호, 성별, 나이, 결제정보
				<br/>
				<br/>
				2. 개인정보의 수집 및 이용 목적
				<br/>
				'회사'가 사용자들의 개인정보를 수집하는 목적은 이용자들의 신분을 확인하고 '서비스'를 이용하고자 하는 의사확인을 위한 것이며, 이용자들에게 최적의 서비스를 제공하고자 하는 것입니다. '회사'는 아래와 같이 수집목적에 따라 개인정보를 이용하고 있습니다. 다만, 이용목적은 수집목적을 벗어나지 않는 범위에서 추가 서비스제공을 위해 확장될 수 있으며, 이 경우에는 미리 공지하여 그 사실을 알려 반드시 고객님께 사전 동의를 구할 것입니다.
				<br/>
				<br/>
				3. 개인정보의 보유 및 이용기간
				<br/>
				개인정보는 수집 및 이용목적 외의 용도로 이용되지 않으며, 관련 담당자를 제외하고는 함부로 열람할 수 없습니다.
				<br/>
				개인정보는 동의 철회 시점까지 보관 됩니다.
				<br/>
			</template>
		</PopupConfirm>
	</div>
</template>

<script>

import PopupConfirm from "../Layout/PopupConfirm";
export default{
	name: 'IndexPaymentAgree'
	, components: {PopupConfirm}
	, props: ['user', 'seller_info']
	,data: function(){
		return {
			program: {
				name: '수기결제 약관동의'
				, title: true
			}
			, items_history: []
			, total_amount: 0
			, total_count: 0
			, is_agree_info: false
			, is_agree_payment: false
			, is_on_agree_info: false
			, is_on_agree_payment: false
		}
	}
	,computed: {
		is_payment: function(){
			let t = false
			if(this.$common.getPowNumber(this.user.is_payment, 2)){
				t = true
			}
			return t
		}
		, is_disabled: function(){
			let t = true
			if(this.is_agree_info && this.is_agree_payment){
				t = false
			}
			return t
		}
	}
	,methods: {
		toPayment: function(){
			this.$bus.$emit('to', { name: 'PaymentItem'})
		}
		, onAgreeInfo: function(){
			this.is_on_agree_info = true
		}
		, onAgreePayment: function(){
			this.is_on_agree_payment = true
		}
		, offAgreeInfo: function(){
			this.is_on_agree_info = false
		}
		, offAgreePayment: function(){
			this.is_on_agree_payment = false
		}
	}
	,created: function(){
		this.$emit('onLoad', this.program)
	}
}

</script>

<style>
</style>

